import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { enabled: Boolean };

  static targets = ["container", "offPosition", "onPosition"];

  connect() {
    this.update();
  }
  toggle() {
    this.enabledValue = !this.enabledValue;
    this.update();
  }

  update(enabled) {
    this.toggleClasses(this.element, ["bg-navy-600"], this.enabledValue);
    this.toggleClasses(
      this.element,
      ["bg-gray-300", "dark:bg-gray-800"],
      !this.enabledValue,
    );

    this.toggleClasses(
      this.containerTarget,
      ["translate-x-6"],
      this.enabledValue,
    );
    this.toggleClasses(
      this.containerTarget,
      ["translate-x-0"],
      !this.enabledValue,
    );

    this.toggleClasses(
      this.offPositionTarget,
      ["opacity-0", "duration-100", "ease-out"],
      this.enabledValue,
    );
    this.toggleClasses(
      this.offPositionTarget,
      ["opacity-100", "duration-200", "ease-in"],
      !this.enabledValue,
    );

    this.toggleClasses(
      this.onPositionTarget,
      ["opacity-100", "duration-200", "ease-in"],
      this.enabledValue,
    );
    this.toggleClasses(
      this.onPositionTarget,
      ["opacity-0", "duration-100", "ease-out"],
      !this.enabledValue,
    );
  }

  toggleClasses(element, classes, enabled) {
    classes.forEach((klass) => {
      element.classList.toggle(klass, enabled);
    });
  }
}
