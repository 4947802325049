import { Controller } from "@hotwired/stimulus";

const stripNonNumeric = (string) => string.replace(/\D/g, "");

export default class extends Controller {
  static targets = ["item", "input"];

  static values = {
    current: String,
  };

  connect() {
    this.currentValue = this.inputTarget.value;

    this.render();
  }

  onSelect(event) {
    this.currentValue = this.inputTarget.value = stripNonNumeric(
      event.currentTarget.dataset.value,
    );

    this.render();
  }

  isSelected(element) {
    return stripNonNumeric(element.dataset.value) === this.currentValue;
  }

  render() {
    this.itemTargets.forEach((element) =>
      element.classList.toggle("active", this.isSelected(element)),
    );
  }
}
