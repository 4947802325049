import { Controller } from "@hotwired/stimulus";
import { kebabCase } from "lodash-es";

export default class extends Controller {
  static targets = ["input", "output"];

  generate() {
    const input = this.inputTarget.value;
    const slug = kebabCase(input);
    this.outputTarget.value = slug;
  }
}
