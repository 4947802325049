import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { with: String };
  static targets = ["disable"];

  connect() {
    this.element.dataset["action"] = "submit->disable#disableForm";
  }

  disableForm() {
    if (this.hasDisableTarget) {
      this.disableTargets.forEach((target) => {
        if (target.dataset.disableClass) {
          target.classList.toggle(target.dataset.disableClass);
        }
      });
    }

    this.submitButtons().forEach((button) => {
      button.disabled = true;

      if (this.hasWithValue) {
        button.innerHTML = this.withValue;
      }
    });
  }

  submitButtons() {
    return this.element.querySelectorAll(
      "button[type='submit'], input[type='submit']",
    );
  }
}
