import {Controller} from "@hotwired/stimulus";
import {enter, leave, toggle} from "el-transition";

export default class extends Controller {
  static targets = ["hideable"];
  static values = {
    animate: { type: Boolean, default: true }
  }

  showAll() {
    this.hideableTargets.forEach((element) => {
      enter(element);
    });
  }

  hideAll(event) {
    this.hideableTargets.forEach((element) => {
      leave(element);
    });
  }

  hideAllFromExternal(event) {
    if (!this.element.contains(event.target)) {
      this.hideableTargets.forEach((element) => {
        leave(element);
      });
    }
  }

  toggleAll() {
    if (this.animateValue) {
      this.hideableTargets.forEach((element) => {
        toggle(element);
      });
    } else {
      this.hideableTargets.forEach((element) => {
        element.classList.toggle("hidden");
      });
    }
  }
}
