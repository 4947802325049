import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
  }

  connect() {
    if (this.element.tagName === "FORM") {
      document.addEventListener("turbo:submit-end", this.onTurboSubmit.bind(this))
    } else {
      this.element.addEventListener("click", this.onClick.bind(this))
    }
  }

  disconnect() {
    if (this.element.tagName === "FORM") {
      document.removeEventListener("turbo:submit-end", this.onTurboSubmit.bind(this))
    }
  }

  onClick(event) {
    this.trackEvent()
  }

  onTurboSubmit(event) {
    if (event.detail.success && event.target === this.element) {
      this.trackEvent()
    }
  }

  trackEvent() {
    if (window.fathom) {
      window.fathom.trackEvent(this.eventNameValue)
    }

    if (window.gtag) {
      window.gtag("event", this.eventNameValue)
    }
  }
}