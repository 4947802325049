import Sortable from "sortablejs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sortable"];

  connect() {
    let options = {
      animation: 150,
      handle: ".sortable",
      forceFallback: true,
    };

    // data-submit-on-update="true"
    if (this.element.dataset.submitOnUpdate) {
      options.onUpdate = this.onUpdate;
    }

    Sortable.create(this.element, options);
  }

  onUpdate = () => {
    this.element.requestSubmit();
  };
}
