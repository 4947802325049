import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

// Usage: hover over - <span class="cursor-pointer" data-controller="tooltip" data-tooltip-trigger-value="mouseenter focus" data-tooltip-content-value="hello!">Due for inspection</span>
// Usage: on click - <span class="cursor-pointer" data-controller="tooltip" data-action="click->tooltip#open" data-tooltip-content-value="hello!">Due for inspection</span>

export default class extends Controller {
  static values = {
    content: String,
    trigger: {
      type: String,
      default: "manual",
    },
  };

  connect() {
    this.tooltip = tippy(this.element, {
      content: this.contentValue,
      trigger: this.triggerValue,
      placement: "top",
    });
  }

  disconnect() {
    this.tooltip?.destroy();
  }

  open() {
    this.tooltip.show();
  }
}
