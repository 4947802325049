import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static classes = ["active"];
  static values = { active: Boolean };

  connect() {
    if (this.hasActiveValue && this.activeValue == true) {
      this.activeValue = !this.activeValue;
      this.toggle();
    }
  }

  toggle() {
    this.activeValue = !this.activeValue;

    this.contentTargets.forEach((element) =>
      element.classList.toggle(this.activeClass),
    );
  }
}
