import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    behavior: {
      type: String,
      default: "smooth",
    },
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.scroll);
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll);
  }

  scroll(event) {
    event.preventDefault();

    const element = document.querySelector(this.element.hash);

    element?.scrollIntoView({
      behavior: this.behaviorValue,
    });
  }
}
