document.addEventListener("trix-before-initialize", () => {
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
  };
  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
  };
});

addEventListener("trix-initialize", (event) => {
  const { toolbarElement } = event.target;

  const h1Button = toolbarElement.querySelector(
    "[data-trix-attribute=heading1]",
  );
  h1Button.insertAdjacentHTML(
    "afterend",
    `
    <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
  `,
  );

  const h2Button = toolbarElement.querySelector(
    "[data-trix-attribute=heading2]",
  );
  h2Button.insertAdjacentHTML(
    "afterend",
    `
    <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
  `,
  );
});
