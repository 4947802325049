import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { seconds: Number };
  static targets = ["bar"];

  connect() {
    const updatesCount = 40;
    let progress = 0;
    this.interval = setInterval(
      () => {
        progress += 100 / updatesCount;
        this.animate(progress);
        if (progress >= 100) {
          clearInterval(this.interval);
        }
      },
      (this.secondsValue * 1000) / updatesCount,
    );
  }

  animate(progress) {
    this.barTarget.style.width = `${progress}%`;
  }
}
