import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video";

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gallery"];
  static values = { selector: String };

  connect() {
    this.gallery = lightGallery(this.galleryTarget, {
      plugins: [lgVideo],
      download: false,
      licenseKey: "F9BC0D7C-44834364-93B1FAB1-9468E4E4",
      selector: this.selectorValue,
    });
  }

  disconnect() {
    this.gallery?.destroy();
  }

  get gallery() {
    return window.lgData[this.galleryTarget.getAttribute("lg-uid")];
  }
}
