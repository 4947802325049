import "@hotwired/turbo-rails";
import "@rails/actiontext";
import "trix";
import "chartkick/chart.js";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "./controllers";
import "./components/direct_uploads";
import "./components/analytics";
import "./components/trix";
