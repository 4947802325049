import {Controller} from "@hotwired/stimulus"
import {leave} from "el-transition"

export default class extends Controller {
  static targets = ["hiddenField"]

  commence(event) {
    this.hiddenFieldTarget.value = "1"

    leave(this.element)

    setTimeout(
      () => {
        this.element.style.display = "none"
      },
      250
    )
  }
}