import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
  };

  connect() {
    this.tooltip = tippy(this.element, {
      content: "Copied!",
      trigger: "manual",
      placement: "bottom",
    });
  }

  copy() {
    navigator.clipboard.writeText(this.contentValue);

    this.tooltip.show();

    setTimeout(() => {
      this.tooltip.hide();
    }, 1000);
  }

  disconnect() {
    this.tooltip?.destroy();
  }
}
