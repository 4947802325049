import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.preserveScroll ||= {};

    this.element.addEventListener("scroll", this.onElementScroll.bind(this));

    this.restoreScroll();
  }

  disconnect() {
    super.disconnect();

    this.element.removeEventListener("scroll", this.onElementScroll);
  }

  onElementScroll() {
    window.preserveScroll[this.element.id] = this.element.scrollLeft;
  }

  restoreScroll() {
    if (window.preserveScroll[this.element.id]) {
      this.element.scrollTo(window.preserveScroll[this.element.id], 0);
    }
  }
}
