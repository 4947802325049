import { Controller } from "@hotwired/stimulus";
import { leave } from "el-transition";

export default class extends Controller {
  static values = {
    countdown: Number,
  };

  commence(event) {
    setTimeout(
      () => {
        this.element.remove();
      },
      this.hasCountdownValue ? this.countdownValue : 250,
    );

    leave(this.element);
  }
}
