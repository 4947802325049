import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["progress"];

  durationSeconds = 3;

  connect() {
    enter(this.element);

    setTimeout(() => {
      leave(this.element);
    }, this.durationSeconds * 1000);
  }
}
