import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "destination"];

  copy(event) {
    let content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );

    if (this.hasDestinationTarget) {
      this.destinationTarget.insertAdjacentHTML("beforeend", content);
    } else {
      this.element.insertAdjacentHTML("beforebegin", content);
    }
  }
}
