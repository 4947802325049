import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["backdrop", "content"];

  connect() {
    Promise.all([enter(this.backdropTarget), enter(this.contentTarget)]);
  }

  onKeydown(event) {
    if (event.key == "Escape" || event.type == "click") {
      this.close();
    }
  }

  onClick(event) {
    if (!this.contentTarget.contains(event.target)) {
      this.close();
    }
  }

  close() {
    Promise.all([leave(this.backdropTarget), leave(this.contentTarget)]).then(
      () => this.element.remove(),
    );
  }
}
