import LocalTime from "local-time";
import { Application } from "@hotwired/stimulus";

LocalTime.start();

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
